import './../css/wishlist.css';
import { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from '../App';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

function Wishlist(props: any) {

    const [lavitrerieProducts, SetLavitrerieProducts] = useState<any>([]);
    const [nodalProducts, SetNodalProducts] = useState<any>([]);
    const [sent, SetSent] = useState(false);
    const [message, SetMessage] = useState('Thank you for your interest. We will contact you soon.')
    const { wishlist, DeleteFromWishlist } = useContext(AppContext);
    const form = useRef<any>(null);
    const recaptchaRef = useRef<any>(null);

    useEffect(() => {

        // La vitrerie
        let index = 0;
        let lavitrerieFilters = 'filters[id][$in][' + index + ']=0';

        wishlist.forEach((item:any) => {
            if(item.section === 'la-vitrerie')
            {
                index++;
                lavitrerieFilters += '&filters[id][$in][' + index + ']=' + item.id;
            }
        });

        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/la-vitrerie-products?' + lavitrerieFilters + '&sort=order&populate=*')
            .then((response: any) => {
                SetLavitrerieProducts(response.data.data);
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });
        
        // Nodal
        index = 0;
        let nodalFilters = 'filters[id][$in][' + index + ']=0';

        wishlist.forEach((item:any) => {
            if(item.section === 'nodal')
            {
                index++;
                nodalFilters += '&filters[id][$in][' + index + ']=' + item.id;
            }
        });

        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/nodal-products?' + nodalFilters + '&sort=order&populate=*')
            .then((response: any) => {
                SetNodalProducts(response.data.data);
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });
        
    }, [wishlist]);

    const onSubmitWithReCAPTCHA = async (e:any) => {

        e.preventDefault();

        const emailExpression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const phoneExpression: RegExp = /^\+?[()\d\s-]*$/i;

        // Validate form
        if(!form.current.elements.name.value)
        {
            alert('Name is required');
            return;
        }
        if(!form.current.elements.email.value)
        {
            alert('Email is required');
            return;
        }
        if(!emailExpression.test(form.current.elements.email.value))
        {
            alert('Email address is not valid');
            return;
        }
        if(!form.current.elements.phone.value)
        {
            alert('Phone is required');
            return;
        }
        if(!phoneExpression.test(form.current.elements.phone.value))
        {
            alert('Phone number is not valid');
            return;
        }

        const token = await recaptchaRef.current.executeAsync();
    
        // Send email with token
        sendEmail(token);
    }

    const sendEmail = (token:any) => {  

        // Build message for sending
        let message:string = '<table style="margin-bottom:100px"><tbody>';

        message += '<tr><td style="padding-right:30px"><strong>Name:</strong></td><td>' + form.current.elements.name.value + '<br />';
        message += '<tr><td><strong>Email:</strong></td><td>' + form.current.elements.email.value + '<br />';
        message += '<tr><td><strong>Phone:</strong></td><td>' + form.current.elements.phone.value + '<br />';
        message += '<tr><td><strong>Message:</strong></td><td>' + form.current.elements.message.value + '<br />';
        message += '<tr><td><strong>Wishlist:</strong></td>';

        // Wishlist
        message += '<td><ul style="margin:0;padding:0;">';
        lavitrerieProducts.forEach((item:any) => {
            message += '<li><strong>' + item.attributes.brand + '</strong> ' + item.attributes.name + '</li>'
        });
        nodalProducts.forEach((item:any) => {
            message += '<li><strong>' + item.attributes.brand + '</strong> ' + item.attributes.name + '</li>'
        });
        message += '</ul></td></tr>'
        message += '</tbody></table>'

        emailjs.send('service_k03d6wh', 'template_zokjygj', { message: message, 'g-recaptcha-response': token }, 'NiwYzl4AzvcrFAN6C')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                SetMessage('Error: ' + error.text);
                console.log(error.text);
            });

        SetSent(true);
    };

    return (
        <Fragment>
            <div id="wishlist" className='content'>
                <div id="wishlist-list">
                    {nodalProducts.length === 0 && lavitrerieProducts.length === 0 &&
                        <div className='wishlist-list-empty'>
                            <div className='wishlist-list-text'>
                                There are no items on your wishlist yet.
                            </div>
                        </div>
                    }
                    {lavitrerieProducts.map((product: any) => 
                        <div className='wishlist-list-item' key={product.id}>
                            <div className='wishlist-list-photo'>
                                <NavLink to={'/la-vitrerie/' + product.attributes.category.data.attributes.name + '/' + product.attributes.slug}>
                                    <img alt={product.attributes.brand + ' ' + product.attributes.name} src={process.env.REACT_APP_STRAPI_URL + product.attributes.photos.data[0].attributes.formats.small.url} />
                                </NavLink>
                            </div>
                            <div className='wishlist-list-text'>
                                <NavLink to={'/la-vitrerie/' + product.attributes.category.data.attributes.name + '/' + product.attributes.slug}>
                                    <span className='wishlist-list-brand'>{product.attributes.brand}</span><br />
                                    <span className='wishlist-list-name'>{product.attributes.name}</span>
                                </NavLink>
                            </div>
                            <div className='wishlist-list-delete'><button onClick={() => { DeleteFromWishlist('la-vitrerie', product.id) }}>X</button></div>
                        </div>
                    )}
                    {nodalProducts.map((product: any) => 
                        <div className='wishlist-list-item' key={product.id}>
                        <div className='wishlist-list-photo'>
                            <NavLink to={'/nodal/' + product.attributes.category.data.attributes.name + '/' + product.attributes.slug}>
                                <img alt={product.attributes.brand + ' ' + product.attributes.name} src={process.env.REACT_APP_STRAPI_URL + product.attributes.photos.data[0].attributes.formats.small.url} />
                            </NavLink>
                        </div>
                        <div className='wishlist-list-text'>
                            <NavLink to={'/nodal/' + product.attributes.category.data.attributes.name + '/' + product.attributes.slug}>
                                <span className='wishlist-list-brand'>{product.attributes.brand}</span><br />
                                <span className='wishlist-list-name'>{product.attributes.name}</span>
                            </NavLink>
                        </div>
                        <div className='wishlist-list-delete'><button onClick={() => { DeleteFromWishlist('nodal', product.id) }}>X</button></div>
                    </div>
                    )}
                </div>
                <div id="wishlist-description">
                    <h1>Wishlist</h1>
                    {sent &&
                        <div className='text'>
                            {message}
                        </div>
                    }
                    {!sent &&
                        <div className='text'>
                            If you would like to rent these products or want to request more information please submit the form below.
                            <form id='wishlist-form' ref={form} onSubmit={onSubmitWithReCAPTCHA}>
                                <table>
                                    <tbody>
                                        <tr><td>Name:</td><td><input type='text' name='name' /></td></tr>
                                        <tr><td>Email:</td><td><input type='text' name='email' /></td></tr>
                                        <tr><td>Phone:</td><td><input type='text' name='phone' /></td></tr>
                                        <tr><td>Message:</td><td><textarea name='message' rows={5}></textarea></td></tr>
                                        <tr><td></td><td><button>Send</button></td></tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    }
                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6LcIN3ImAAAAAG3fCE5XnKAJd4lvdKr9She3wAKj" />
                </div>
            </div>
        </Fragment>
    );
}

export default Wishlist;
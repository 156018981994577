import './../css/header.css';
import { Fragment } from 'react';

function Header()
{
    return (
        <Fragment>
            <div id="header">
                <div id="header-scroll">OTTENDOESITOTTENDOESITOTTENDOESITOTTENDOESITOTTENDOESITOTTENDOESITOTTENDOESITOTTENDOESIT</div>
            </div>
        </Fragment>
    );
}

export default Header;
import React, { Fragment } from 'react';
import './css/style.css';
import Header from './components/header';
import Menu from './components/menu';
import Error from './components/error';
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    NavLink,
  } from "react-router-dom";
import List from './components/list';
import Product from './components/product';
import Footer from './components/footer';
import Page from './components/page';
import Contact from './components/contact';
import Wishlist from './components/wishlist';
import Browse from './components/browse';

export const AppContext = React.createContext<any>(null);

const router = createBrowserRouter([
    {
        path: "/", 
        element: <Root />,
        errorElement: <Error />,
        children: [
            { 
                index: true, 
                element: <Page page='about-us' /> 
            },
            { 
                path: "la-vitrerie", 
                children: [
                    {
                        index: true,
                        element: <><Page page='la-vitrerie-page' /><Browse section='la-vitrerie' /></>
                    },
                    {
                        path: ":category",
                        element: <List section='la-vitrerie' />
                    },
                    {
                        path: ":category/:slug",
                        element: <Product section='la-vitrerie' />
                    }
                ]
            },
            { 
                path: "nodal", 
                children: [
                    {
                        index: true,
                        element: <><Page page='nodal-page' /><Browse section='nodal' /></>
                    },
                    {
                        path: ":category",
                        element: <List section='nodal' />
                    },
                    {
                        path: ":category/:slug",
                        element: <Product section='nodal' />
                    }
                ]
            },
            { 
                path: "workshops", 
                element: <Page page='workshops' />
            },
            { 
                path: "service", 
                element: <Page page='service' /> 
            },
            { 
                path: "contact", 
                element: <Contact /> 
            },
            { 
                path: "wishlist", 
                element: <Wishlist /> 
            },
        ], 
    },
]);

function App() {
    return <RouterProvider router={router} />;
}

interface WishlistItem
{
    section: String;
    id: number;
}

function Root() {

    const [wishlist, setWishlist] = React.useState<WishlistItem[]>(JSON.parse(sessionStorage.getItem('wishlist') || '[]'));

    const AddToWishlist = (section: String, id: number) => {

        wishlist.forEach((item:any) => {
            if(item.id === id && item.section === section)
            {
                return; // Already on wishlist
            }
        });

        sessionStorage.setItem('wishlist', JSON.stringify([...wishlist, {section: section, id: id}]));
        setWishlist(wishlist => [...wishlist, {section: section, id: id}]);
        console.log([...wishlist, {section: section, id: id}]);
    }

    const DeleteFromWishlist = (section: String, id: number) => {
        sessionStorage.setItem('wishlist', JSON.stringify(wishlist.filter(item => (item.section !== section) || (item.section === section && item.id !== id))));
        setWishlist(wishlist => wishlist.filter(item => (item.section !== section) || (item.section === section && item.id !== id)));
        console.log(wishlist.filter(item => (item.section !== section) || (item.section === section && item.id !== id)));
    }

    return (
        <Fragment>
            <AppContext.Provider value={{ wishlist: wishlist, AddToWishlist: AddToWishlist, DeleteFromWishlist: DeleteFromWishlist }}>
                <NavLink id='wishlist-button' to={'/wishlist'}>Wishlist ({wishlist ? wishlist.length : '0'})</NavLink>
                <Header />
                <Menu />
                <div id='content'>
                    <Outlet />
                </div>
                <Footer />
            </AppContext.Provider>
        </Fragment>
    );
}

export default App;

import { NavLink } from 'react-router-dom';
import './../css/browse.css';
import { Fragment, useState, useEffect } from 'react';
import axios from 'axios';

function Browse(props: any)
{
    const [categories, SetCategories] = useState<any>(null);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/' + props.section + '-categories?sort=order&populate=*')
            .then((response: any) => {
                SetCategories(response.data.data)
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });
    }, [props.section]);

    return (
        <Fragment>
            {categories && 
                <div id="browse" className='content'> 
                    <h1>Browse All</h1>
                    {categories.map((category: any) => 
                        <NavLink key={category.id} to={"/" + props.section + "/" + category.attributes.slug}>{category.attributes.name}</NavLink>
                    )}
                </div>
            }
        </Fragment>
    );
}

export default Browse;
import './../css/list.css';
import { Fragment, useEffect, useState } from 'react';
import { NavLink, useParams } from "react-router-dom";
import axios from 'axios';
import { wrapGrid } from 'animate-css-grid';

function List(props: any)
{
    let { category } = useParams();
    const [products, setProducts] = useState([]);
    const [tag, setTag] = useState('all');
    const [filters, setFilters] = useState<string[]>([]);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/' + props.section + '-products?&filters[$and][0][category][slug][$eq]=' + category + '&sort=order&populate=deep')
            .then((response: any) => {
                setProducts(response.data.data);
                makeFilterList(response.data.data);
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });
    }, [category, props.section]);

    useEffect(() => {
        const grid:any = document.querySelector("#list-products");
        wrapGrid(grid, {duration : 500, stagger: 0});
    }, []);

    const setFilter = (e:any) => 
    {
        e.preventDefault();

        const tag = e.target.dataset.tag;
        setTag(tag)

        // Loop through list
        document.querySelectorAll('.list-product').forEach((product:any) => 
        {
            if(product.dataset.tags.includes(tag) || tag === 'all')
            {
                // Show it
                product.classList.remove("hidden");

            }
            else
            {
                // Hide it
                product.classList.add("hidden");
            }
        });
    }

    const getTagList = (tags:any) => 
    {
        let tagList = '';

        if(tags)
        {
            tags.forEach((t: any) => 
            {
                tagList += t.attributes.name + ',';
            });
        }

        return tagList;
    }

    const makeFilterList = (products:any) => {

        var filterList:string[] = [];

        products.forEach((product:any) => {
            product.attributes.tags.data.forEach((tag:any) => {
                if(!filterList.includes(tag.attributes.name)) filterList.push(tag.attributes.name);
            });
        });

        setFilters(filterList);
    }

    return (
        <Fragment>
            <div className='content'>
                <div id="list-filters">
                    Filter by <button className={tag === 'all' ? 'tag active' : 'tag'} onClick={setFilter} data-tag='all'>All</button>
                    {filters.map((filter: any) => 
                        <button className={tag === filter ? 'tag active' : 'tag'} onClick={setFilter} data-tag={filter}>{filter}</button>
                    )}
                </div>
                <div id="list-products">
                    {[...Array(1)].map((x, i) =>
                    <Fragment key={i}>
                    {products.map((product: any) => 
                        <NavLink key={product.id} to={'/' + props.section + '/' + category + '/' + product.attributes.slug} className={'list-product'} data-tags={getTagList(product.attributes.tags.data)}>
                            <div>
                            <div className='list-product-photo'>
                                <img alt={product.attributes.brand + ' ' + product.attributes.name} src={process.env.REACT_APP_STRAPI_URL + product.attributes.photos.data[0].attributes.formats.small.url} />
                                <div className='list-product-photo-overlay'></div>
                            </div>
                            <div className='list-product-brand'>{product.attributes.brand}</div>
                            <div className='list-product-name'>{product.attributes.name}</div>
                            {product.attributes.tags.data.map((tag: any) =>
                                <button key={tag.id} className='tag'>{tag.attributes.name}</button>
                            )}
                            </div>
                        </NavLink>
                    )}
                    </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

export default List;
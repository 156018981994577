import './../css/footer.css';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios'; 
import Socials from './socials';

function Footer()
{
    const [footer, setFooter] = useState<any>(null);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/footer?populate=deep')
            .then((response: any) => {
                setFooter(response.data.data.attributes)
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });
    }, []);

    return (
        <Fragment>
            {footer &&
                <div id="footer" className='content'>
                    <span id="footer-logo">OTTENDOESIT</span>
                    <span className='footer-info'>{footer.tagline}</span>
                    <span className='footer-info'>{footer.phone}</span>
                    <span className='footer-info'><a href={'maito:' + footer.email}>{footer.email}</a></span>
                    <span className='footer-socials'><Socials socials={footer.socials} /></span>
                </div>
            }
        </Fragment>
    );
}

export default Footer;
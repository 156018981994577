import './../css/error.css';
import { Fragment } from 'react';
import Header from './header';
import Menu from './menu';
import Footer from './footer';

function Error()
{
    return (
        <Fragment>
            <Header />
            <Menu />
            <div className='content'>
                Whoops! This page could not be found.
            </div>
            <Footer />
        </Fragment>
    );
}

export default Error;
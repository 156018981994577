import './../css/page.css';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';

function Page(props: any) {

    const [page, setPage] = useState<any>(null);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/' + props.page + '?populate=deep')
            .then((response: any) => {
                setPage(response.data.data.attributes);
                console.log(response.data.data.attributes);
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });
    }, [props.page]);

    return (
        <Fragment>
            {page &&
                <div id="page" className='content'>
                    {page.content.map((content: any, index: number) => 
                        <Fragment key={index}>
                            {content.__component === 'pages.content-block' &&
                                <div className='page-content-block'>
                                    {content.type === 'Photo Left' &&
                                        <Fragment>
                                            <div className='page-content-block-photo'>
                                                {content.photo.data &&
                                                    <img alt={content.title} src={process.env.REACT_APP_STRAPI_URL + content.photo.data.attributes.formats.medium.url} />
                                                }
                                            </div>
                                            <div className='page-content-block-content'>
                                                {content.title &&
                                                    <h1>{content.title}</h1>
                                                }
                                                <div className='page-content-block-text text' dangerouslySetInnerHTML={{__html: content.text}}></div>
                                            </div>
                                        </Fragment>
                                    }
                                    {content.type === 'Photo Right' &&
                                        <Fragment>
                                            <div className='page-content-block-content'>
                                                {content.title &&
                                                    <h1>{content.title}</h1>
                                                }
                                                <div className='page-content-block-text text' dangerouslySetInnerHTML={{__html: content.text}}></div>
                                            </div>
                                            <div className='page-content-block-photo'>
                                                {content.photo.data &&
                                                    <img alt={content.title} src={process.env.REACT_APP_STRAPI_URL + content.photo.data.attributes.formats.medium.url} />
                                                }
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            }
                            {content.__component === 'pages.video-and-text' &&
                                <div className='page-content-block'>
                                    {content.type === 'Video Left' &&
                                        <Fragment>
                                            <div className='page-content-block-photo'>
                                                {content.video &&
                                                    <div style={{padding:'56.25% 0 0 0', position:'relative'}}>
                                                        <iframe src={'https://player.vimeo.com/video/' + content.video[0] + '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} 
                                                            frameBorder={0} 
                                                            allow="autoplay; fullscreen; picture-in-picture" 
                                                            allowFullScreen 
                                                            style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} 
                                                            title={content.video[0]}
                                                            >
                                                        </iframe>
                                                        <script src="https://player.vimeo.com/api/player.js"></script>
                                                    </div>
                                                }
                                            </div>
                                            <div className='page-content-block-content'>
                                                {content.title &&
                                                    <h1>{content.title}</h1>
                                                }
                                                <div className='page-content-block-text text' dangerouslySetInnerHTML={{__html: content.text}}></div>
                                            </div>
                                        </Fragment>
                                    }
                                    {content.type === 'Video Right' &&
                                        <Fragment>
                                            <div className='page-content-block-content'>
                                                {content.title &&
                                                    <h1>{content.title}</h1>
                                                }
                                                <div className='page-content-block-text text' dangerouslySetInnerHTML={{__html: content.text}}></div>
                                            </div>
                                            <div className='page-content-block-photo'>
                                                {content.video && content.video[0] && 
                                                    <div style={{padding:'56.25% 0 0 0', position:'relative'}}>
                                                        <iframe src={'https://player.vimeo.com/video/' + content.video[0] + '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} 
                                                            frameBorder={0} 
                                                            allow="autoplay; fullscreen; picture-in-picture" 
                                                            allowFullScreen 
                                                            style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} 
                                                            title={content.video[0]}>
                                                        </iframe>
                                                        <script src="https://player.vimeo.com/api/player.js"></script>
                                                    </div>
                                                }
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            }
                            {content.__component === 'pages.text' &&
                                <div className='page-content-text'>
                                    <div className='text' dangerouslySetInnerHTML={{__html: content.text}}></div>
                                </div>
                            }
                            {content.__component === 'pages.title' &&
                                <div className='page-content-title'>
                                    <h1>{content.title}</h1>
                                </div>
                            }
                        </Fragment>
                    )}
                </div>
            }
        </Fragment>
    );
}

export default Page;
import './../css/socials.css';
import { Fragment } from 'react';
import Facebook from './../img/social-facebook.svg';
import Instagram from './../img/social-instagram.svg';
import LinkedIn from './../img/social-linkedin.svg';
import IMDB from './../img/social-imdb.svg';
import Vimeo from './../img/social-vimeo.svg';
import YouTube from './../img/social-youtube.svg';
import Twitter from './../img/social-twitter.svg';
import Website from './../img/social-website.svg';

function Socials(props: any) {

    const getSocialIcon = (social:string) => 
    {
        let socialIcon:string = Website;
        if(social === 'Facebook') socialIcon = Facebook;
        if(social === 'Instagram') socialIcon = Instagram;
        if(social === 'LinkedIn') socialIcon = LinkedIn;
        if(social === 'IMDB') socialIcon = IMDB;
        if(social === 'Vimeo') socialIcon = Vimeo;
        if(social === 'YouTube') socialIcon = YouTube;
        if(social === 'Twitter') socialIcon = Twitter;
        return socialIcon;
    }

    return (
        <Fragment>
            {props.socials && props.socials.map((social: any) => 
                <Fragment key={social.id}>
                    <a href={social.url} target='_blank' rel='noreferrer'><img className='socials-icon' alt={social.platform} src={getSocialIcon(social.platform)} /></a>
                </Fragment>
            )}
        </Fragment>
    );
}

export default Socials;
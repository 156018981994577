import './../css/product.css';
import { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from '../App';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import ArrowLeft from './../img/arrow-left.svg';
import ArrowRight from './../img/arrow-right.svg';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Product(props: any) {

    let { slug } = useParams();
    const [product, SetProduct] = useState<any>(null);
    const { wishlist, AddToWishlist, DeleteFromWishlist } = useContext(AppContext);
    const [onWishlist, SetOnWishlist] = useState(false);
    const [videos, SetVideos] = useState<any>(null);
    const photos:any = useRef();
    const descriptionContainer:any = useRef();
    const descriptionReadMore:any = useRef();
    const [readMore, SetReadMore] = useState(false);
    const [descriptionOverflowing, SetDescriptionOverflowing] = useState(false);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/' + props.section + '-products?filters[slug][$eq]=' + slug + '&sort=order&populate=deep')
            .then((response: any) => {
                SetProduct(response.data.data[0])
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });

    }, [props.section, slug]);

    useEffect(() => {

        if(product === null) return;
        if(product.attributes.videos === null) return;

        let uris = '';
        let first = true;

        product.attributes.videos.forEach((video:any) => {
            if(!first) 
            {
                uris += ',';
            }
            else
            {
                first = false;
            }

            uris += '/videos/' + video;
        });

        axios
            .get('https://api.vimeo.com/videos?uris=' + uris, {
                headers: {
                    Authorization: 'Bearer 5f9789b4a1582c6b84a754f56a8edfaa',
                }
            })
            .then((response) => {
                SetVideos(response.data.data);
                console.log(response.data.data);
            })
            .catch((error) => 
            {
                console.log(error.message);
            });
    }, [product])

    useEffect(() => {

        let checkOnWishlist = false;

        if(product && wishlist)
        {
            wishlist.forEach((item:any) => {
                if(item.id === product.id && item.section === props.section)
                {
                    checkOnWishlist = true;
                }
            });
        }

        SetOnWishlist(checkOnWishlist);

    }, [wishlist, product, props.section]);

    useEffect(() => {
        resizePhotosAndDescription();
    }, [product]);

    useEffect(() => {

        window.addEventListener('resize', (event: any) => 
        {
            resizePhotosAndDescription();
        });

        Fancybox.bind("[data-fancybox]", {
            // Your custom options
        });
        
    }, [product]);

    const resizePhotosAndDescription = () => 
    {
        if(photos.current)
        {
            let photosHeight = photos.current.clientWidth / 3 * 2;
            photos.current.style.height = photosHeight + 'px';
            photos.current.setAttribute("style","height:" + photosHeight + "px");

            if(descriptionContainer.current)
            {
                if(!readMore && window.innerWidth > 1111)
                {
                    let descriptionHeight = photos.current.clientHeight + 70;
                    descriptionContainer.current.style.height = descriptionHeight + 'px';
                    descriptionContainer.current.setAttribute("style","height:" + descriptionHeight + "px");
                }
                else
                {
                    descriptionContainer.current.setAttribute("style","height: auto");
                }
            }
        }

        if(descriptionContainer.current)
        {
            SetDescriptionOverflowing(descriptionContainer.current.scrollHeight > descriptionContainer.current.clientHeight);
        }
    }

    const getIdFromUri = (uri:string) => 
    {
        return uri.replace("/videos/", "");
    }

    const openReadMore = () => 
    {
        if(!readMore)
        {
            descriptionContainer.current.setAttribute("style","height:" + descriptionContainer.current.scrollHeight + "px");
            descriptionReadMore.current.setAttribute("style","opacity: 0");
            SetReadMore(true);
        }
    }

    return (
        <Fragment>
            {product &&
                <div id="product" className='content'>
                    <div id="product-main">
                        <div id="product-photos">
                            <div id="product-photos-slider">
                                <Swiper id='product-photos-swiper' 
                                    modules={[Autoplay,Navigation]} 
                                    grabCursor={true} 
                                    loop={true} 
                                    autoplay={{ delay: 4000 }}
                                    navigation={{
                                        nextEl: '#product-photos-button-right',
                                        prevEl: '#product-photos-button-left',
                                    }}
                                    ref={photos}
                                    >
                                    {product.attributes.photos.data.map((photo: any) => 
                                        <SwiperSlide key={photo.id} data-fancybox="photos" data-src={photo.attributes.formats.large ? process.env.REACT_APP_STRAPI_URL + photo.attributes.formats.large.url : process.env.REACT_APP_STRAPI_URL + photo.attributes.url}> 
                                            <img  alt={product.attributes.brand + ' ' + product.attributes.name} src={process.env.REACT_APP_STRAPI_URL + photo.attributes.formats.medium.url} />
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                                <div id='product-photos-overlay'></div>
                                <div id='product-photos-navigation'>
                                    <div id='product-photos-button-left'><img alt='Previous' src={ArrowLeft} /></div>
                                    <div id='product-photos-button-right'><img alt='Next' src={ArrowRight} /></div>
                                </div>
                            </div>
                            <div id='product-wishlist'>
                                {!onWishlist &&
                                    <button id='product-wishlist-add' onClick={() => AddToWishlist(props.section, product.id) }>+ Add to wishlist</button>
                                }
                                {onWishlist &&
                                    <button id='product-wishlist-add' onClick={() => DeleteFromWishlist(props.section, product.id) }>- Remove from wishlist</button>
                                }
                                <button id='product-wishlist-button'><NavLink to={'/wishlist'}>Wishlist ({wishlist ? wishlist.length : '0'})</NavLink></button>
                            </div>
                        </div>
                        <div id="product-description">
                            <div id="product-description-container" ref={descriptionContainer}>
                                <h1 className='nomargin'>{product.attributes.brand}</h1>
                                <h3>{product.attributes.name}</h3>
                                <div className='text' dangerouslySetInnerHTML={{__html: product.attributes.description}}></div>
                            </div>
                            {descriptionOverflowing &&
                                <div id="product-description-readmore" ref={descriptionReadMore}><button onClick={() => openReadMore() }>READ MORE</button></div>
                            }
                        </div>
                    </div>
                    {videos &&
                        <div id="product-shotwith">
                            <h1 className='nomargin'>Shot with</h1>
                            <h3>{product.attributes.brand} {product.attributes.name}</h3>
                            <div id='product-videos'>
                                {videos.map((video: any) =>
                                    <div key={video.uri}>
                                        <div style={{padding:'56.25% 0 0 0', position:'relative'}}>
                                            <iframe src={'https://player.vimeo.com/video/' + getIdFromUri(video.uri) + '?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'} 
                                                frameBorder={0} 
                                                allow="autoplay; fullscreen; picture-in-picture" 
                                                allowFullScreen 
                                                style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} 
                                                title={video.title}>
                                            </iframe>
                                            <script src="https://player.vimeo.com/api/player.js"></script>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            }
        </Fragment>
    );
}

export default Product;
import { NavLink, useLocation } from 'react-router-dom';
import './../css/menu.css';
import { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import Hamburger from './../img/hamburger.svg';

function Menu()
{
    const [hamburger, SetHamburger] = useState(false);
    const [lavitrerieCats, SetLavitrerieCats] = useState<any>(null);
    const [nodalCats, SetNodalCats] = useState<any>(null);
    const location = useLocation();

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/la-vitrerie-categories?sort=order&populate=*')
            .then((response: any) => {
                SetLavitrerieCats(response.data.data)
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });

        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/nodal-categories?sort=order&populate=*')
            .then((response: any) => {
                SetNodalCats(response.data.data)
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });
    }, []);

    return (
        <Fragment>
            {lavitrerieCats && nodalCats &&
                <div id="menu"> 
                    <div id="menu-desktop" className='content'>
                        <div id='menu-buttons' className='menu-buttons'>
                            <NavLink className='menu-button' to='/'>About Us</NavLink>
                            <NavLink className={location.pathname.startsWith('/la-vitrerie') ? 'menu-button active' : 'menu-button'} to='/la-vitrerie/'>La Vitrerie</NavLink>
                            <NavLink className={location.pathname.startsWith('/nodal') ? 'menu-button active' : 'menu-button'} to='/nodal/'>Nodal</NavLink>
                            <NavLink className='menu-button' to='/for-sale'>For Sale</NavLink>
                            <NavLink className='menu-button' to='/workshops'>Workshops</NavLink>
                            <NavLink className='menu-button' to='/service'>Service</NavLink>
                            <NavLink className='menu-button' to='/contact'>Contact</NavLink>      
                        </div>
                        {location.pathname.startsWith('/la-vitrerie') && 
                            <div className='menu-submenu menu-buttons'>
                                {lavitrerieCats.map((category: any) => 
                                    <NavLink key={category.id} to={"/la-vitrerie/" + category.attributes.slug}>{category.attributes.name}</NavLink>
                                )}
                            </div>
                        }
                        {location.pathname.startsWith('/nodal') && 
                            <div className='menu-submenu menu-buttons'>
                                {nodalCats.map((category: any) => 
                                    <NavLink key={category.id} to={"/nodal/" + category.attributes.slug}>{category.attributes.name}</NavLink>
                                )}
                            </div>
                        }
                    </div>
                    <div id="menu-mobile" className={hamburger ? 'open menu-buttons content' : 'closed menu-buttons content'}>
                        <a id="menu-hamburger" onClick={() => SetHamburger(!hamburger)}><img src={Hamburger} alt='Menu' />Menu</a>
                        <NavLink className='menu-button' to='/' onClick={() => SetHamburger(false)}>About Us</NavLink>
                        <NavLink className={location.pathname.startsWith('/la-vitrerie') ? 'menu-button active' : 'menu-button'} to={'/la-vitrerie/' + lavitrerieCats[0].attributes.name} onClick={() => SetHamburger(false)}>La Vitrerie</NavLink>
                        {lavitrerieCats.map((category: any) => 
                            <NavLink key={category.id} className ='menu-button menu-button-submenu' to={"/la-vitrerie/" + category.attributes.name} onClick={() => SetHamburger(false)}>{category.attributes.name}</NavLink>
                        )}
                        <NavLink className={location.pathname.startsWith('/nodal') ? 'menu-button active' : 'menu-button'} to={'/nodal/' + nodalCats[0].attributes.name} onClick={() => SetHamburger(false)}>Nodal</NavLink>
                        {nodalCats.map((category: any) => 
                            <NavLink key={category.id} className ='menu-button menu-button-submenu' to={"/nodal/" + category.attributes.name} onClick={() => SetHamburger(false)}>{category.attributes.name}</NavLink>
                        )}
                        <NavLink className='menu-button' to='/for-sale' onClick={() => SetHamburger(false)}>For Sale</NavLink>
                        <NavLink className='menu-button' to='/workshops' onClick={() => SetHamburger(false)}>Workshops</NavLink>
                        <NavLink className='menu-button' to='/service' onClick={() => SetHamburger(false)}>Service</NavLink>
                        <NavLink className='menu-button' to='/contact' onClick={() => SetHamburger(false)}>Contact</NavLink>  
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default Menu;
import './../css/contact.css';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import Socials from './socials';

function Contact(props: any) {

    const [contact, setContact] = useState<any>(null);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/contact?populate=deep')
            .then((response: any) => {
                setContact(response.data.data.attributes)
            })
            .catch((error: any) => 
            {
                console.log(error.message);
            });
    }, []);

    return (
        <Fragment>
            {contact &&
                <div id="contact" className='content'>
                    {contact.content.map((content: any) => 
                        <Fragment key={content.id}>
                            <div className='contact-block'>
                                {content.photo.data &&
                                    <img className='contact-photo' alt={content.name} src={process.env.REACT_APP_STRAPI_URL + content.photo.data.attributes.formats.small.url} />
                                }
                                {content.name &&
                                    <h1 className='nomargin'>{content.name}</h1>
                                }
                                {content.tags &&
                                    <div className='contact-block-tags'>
                                        {content.tags.map((tag: any) =>
                                            <span id={tag.id} className='tag'>{tag.tag}</span>
                                        )}
                                    </div>
                                }
                                <div className='contact-block-text text' dangerouslySetInnerHTML={{__html: content.text}}></div>
                                <div className='contact-block-social'>
                                    <Socials socials={content.socials} />
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            }
        </Fragment>
    );
}

export default Contact;